import { useState } from 'react';
import MastercardLink from './MastercardLink';
import { Box, Button, Stack, Typography } from '@mui/material';

export const MasterCardLinkTest = () => {
  const [showDialog, setShowDialog] = useState(false)

  return (<>
    {!showDialog && (
      <Stack spacing={2} style={{ margin: "50px" }}>
        <Typography variant="h3">Mastercard Linking Demo</Typography>
        <Box><Button variant="contained" onClick={() => setShowDialog(true)}>Add Your Mastercard</Button></Box>
      </Stack>
    )}
    <MastercardLink display={showDialog} />
  </>)
}

