import { CircularProgress } from "@mui/material";
import { get } from "./http";
import { useEffect, useRef, useState, CSSProperties } from "react"
import { useViewport } from 'react-viewport-hooks';

const ConsentUI = (window as any).ConsentUI;

export interface MastercardTokenResponse {
    token: string
    url: string
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: { display: boolean }) => {
    const [config, setConfig] = useState<MastercardTokenResponse>()
    const showDialog = props.display
    const div = useRef<HTMLDivElement>(null)
    const { vw, vh } = useViewport()
    const style: CSSProperties = {
        position: "fixed",
        top: 0,
        left: 0,
        height: `${vh}px`,
        width: `${vw}px`,
    }

    console.log("The div is", div.current, style)

    useEffect(() => {
        get(`/api/mastercard/token?callbackUrl=${encodeURIComponent(window.location.toString())}`, (status, body) => {
            if (status === 200) {
                setConfig(JSON.parse(body))
            }
        })
    }, [])


    useEffect(() => {
        if (config && div.current) {
            console.log("ConsentUI is", ConsentUI)
            let consentUI = new ConsentUI({
                container: ".consent-ui",
                jwt: config.token,
                src: config.url,
                callback: (msg: any) => console.log("callback received:", msg)
            });
        }
    }, [config, showDialog, div])


    return (<>
        {showDialog && <div ref={div} className="consent-ui" style={style}>
            <CircularProgress />
        </div>}
    </>)
}