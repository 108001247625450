import React, { ReactNode, useEffect, useState } from "react"
import { ActivityListItem, Member, MemberCardDto, NonSignatureParkingVisit, PhoneNumber, SmsAccountDto, TransactionDetails, WalletEntry } from "./api"
import { get } from "./http"
import { Alert, AppBar, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Link, MenuItem, Select, Slide, Stack, TextField, Toolbar, Typography } from "@mui/material"

import { useParams } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';

import { TransitionProps } from '@mui/material/transitions';
import MembershipCard from "./MembershipCard"
import dayjs, { Dayjs } from "dayjs"
import { DateTimePicker } from "@mui/x-date-pickers"
import CoinTranchesHistory from "./CoinTranchesHistory"
import ExportLink from "./ExportLink"
import RoutedTabs, { TabThing } from "./RoutedTabs"
import { TheEventsViewer } from "./EventsViewer"
import _ from "underscore"
import { MemberFixesTab } from "./MemberFixes"
import { getActivitiesForMember, getMemberCard, getMemberPromise, getSmsAccountForMember, getWallet, removeSmsNumberForMember, setSmsNumberForMember } from "./api-actions"
import { ActivityList, toDetails } from "./ActivityList"

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { formatPlural } from "./Util"
import { MuiTelInput } from "mui-tel-input"


export const WalletView = (props: { memberId: string }) => {
    const { memberId } = props

    const [busy, setBusy] = useState(false)
    const [entries, setEntries] = useState<WalletEntry[]>()
    const [error, setError] = useState<string>()

    useEffect(() => {
        setBusy(true)
        getWallet(memberId)
            .then(r => setEntries(r.payload.data ?? []))
            .catch(e => setError(`${e}`))
            .finally(() => setBusy(false))
    }, [])

    return (
        <>
            {busy && <CircularProgress />}
            {error && <Alert color="error">{error}</Alert>}
            {entries && <Stack spacing={2}>
                <Typography variant="subtitle1">{entries.length} wallet {formatPlural(entries.length, "entry", "entries")}:</Typography>
                {entries.map(entry => {
                    return (<>
                        <Alert color="info" style={{ maxWidth: "500px" }}>This is just the raw information in the wallet - this is not the way the item will appear in the member's app.</Alert>
                        <Card style={{ maxWidth: "800px" }}>

                            <CardMedia
                                sx={{ height: 140 }}
                                image={entry.image_url}
                                title={entry.heading}
                            />
                            <CardContent>
                                <Stack spacing={3}>
                                    <Typography gutterBottom variant="h4" component="div">
                                        {entry.heading}
                                    </Typography>

                                    <Typography gutterBottom variant="caption" component="div">
                                        Wallet Entry ID: {entry.id}
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Subheading
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {entry.subheading}
                                    </Typography>
                                    <Divider />

                                    <Typography gutterBottom variant="h5" component="div">
                                        Details
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {entry.details}
                                    </Typography>
                                    <Divider />

                                    <Typography gutterBottom variant="h5" component="div">
                                        Body
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        <div dangerouslySetInnerHTML={{ __html: entry.body }} />
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </>)
                })}
            </Stack>}
        </>
    )
}


interface VoucherIssueQueueEntry {
    whenAdded: number,
    voucher: ParkingVoucher,
    reasonIssued: ParkingAuthorizationDecision | undefined,
}


interface ParkingVoucher {
    email: string,
    voucherId: string,
    voucherExpiryDate: string
}
interface ParkingAuthorizationDecision {
    type: "unlimited" | "one-time-pass"
}
interface UnlimitedParkingAuthorization extends ParkingAuthorizationDecision {
    type: "unlimited"
    segments: string[]
}
interface OneTimePassAuthorization extends ParkingAuthorizationDecision {
    type: "one-time-pass"
    offerResponseId: number
}


export const ParkingAuthorizationsPanel = (props: { memberId: string }) => {
    const { memberId } = props
    const [authorizations, setAuthorizations] = useState<VoucherIssueQueueEntry[]>()
    useEffect(() => {
        get(`/api/members/${memberId}/parking/authorizations`, (status, body) => {
            setAuthorizations(JSON.parse(body))
        })

    }, [])

    return (<>
        <Box>
            <Alert style={{ display: "inline-block" }} color="info">This shows the most recent authorizations sent to the parking system.</Alert>
        </Box>
        {!authorizations ? <CircularProgress /> : authorizations.map(a => {
            const whenAdded = dayjs(a.whenAdded)
            return <Card>
                <CardContent>
                    <Typography variant="h6">Overview</Typography>
                    <Typography>ID: {a.voucher.voucherId}</Typography>
                    <Typography>When: {whenAdded.format("YYYY-MM-DD")} {whenAdded.format("h:mm a ZZ")}</Typography>
                    <Typography variant="h6">Data Sent</Typography>
                    <pre>{JSON.stringify(a.voucher, null, 4)}</pre>

                    <Typography variant="h6">Reason Issued</Typography>
                    <pre>{JSON.stringify(a.reasonIssued, null, 4)}</pre>
                </CardContent>
            </Card>
        })}
    </>)
}


interface FidelCard {

    scheme: string, //  "visa",
    accountId: string, //  "3693ac7e-3e2b-432c-8c60-2b786453ca9b",
    countryCode: string, //  "GBR",
    created: string, //  "2018-09-10T10:30:50.494Z",
    expYear: string, //  2019,
    expDate: string, //  "2019-10-01T00:00:00.000Z",
    live: string, //  false,
    lastNumbers: string, //  "4222",
    expMonth: string, //  10,
    updated: string, //  "2018-09-10T10:30:50.494Z",
    programId: string, //  "f76ed1be-e434-480b-aa1d-ff48f548f62a",
    firstNumbers: string, //  "444400",
    id: string, //  "612ff311-dc0c-448d-8199-5a371f07433d",
    type: string, //  "visa"
    metadata: Record<string, string> | undefined, // {"member_id": "U-004992291"}
}

export const CardsPanel = (props: { memberId: string }) => {
    const { memberId } = props
    const [cards, setCards] = useState<FidelCard[]>()
    useEffect(() => {
        get(`/api/members/${memberId}/fidel-cards`, (status, body) => {
            setCards(JSON.parse(body))
        })

    }, [])

    return (<>
        {/* <Box>
            <Alert style={{display:"inline-block"}} color="info">This shows the most recent authorizations sent to the parking system.</Alert>
        </Box> */}
        {!cards ? <CircularProgress /> : cards.map(card => {
            return <Card>
                <CardContent>
                    <pre>{JSON.stringify(card, null, 4)}</pre>
                </CardContent>
            </Card>
        })}
    </>)
}



interface StoredParkingUse {
    whenReceived: number
    use: ParkingUse
}
interface ParkingUse {
    id: string
    property: string | undefined
    timeOut: string | undefined
}



interface CombinedThing {
    barcodeExit: StoredParkingUse | undefined
    otherVisit: NonSignatureParkingVisit | undefined
}


export const ParkingExitsPanel = (props: { memberId: string }) => {
    const { memberId } = props
    const [exits, setExits] = useState<StoredParkingUse[]>()
    const [visits, setVisits] = useState<NonSignatureParkingVisit[]>()

    useEffect(() => {
        get(`/api/members/${memberId}/parking/exits`, (status, body) => {
            setExits(JSON.parse(body))
        })

    }, [])

    useEffect(() => {
        get(`/api/members/${memberId}/parking/visits`, (status, body) => {
            setVisits(JSON.parse(body))
        })
    }, [])

    const a: CombinedThing[] = (exits ?? []).map(e => { return { barcodeExit: e, otherVisit: undefined } })

    const b: CombinedThing[] = (visits ?? []).map(v => { return { barcodeExit: undefined, otherVisit: v } })

    const combinedThings: CombinedThing[] = _.sortBy(a.concat(b), c => c.barcodeExit?.use?.timeOut ?? c.otherVisit?.entry?.transactionDate).reverse()

    return (<>
        <Stack spacing={3}>

            {(!exits || !visits) ? <CircularProgress /> : combinedThings.map(c => {
                const { barcodeExit, otherVisit } = c

                if (otherVisit) {
                    const v = otherVisit
                    return <>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Standard Visit</Typography>
                                <Typography>Transaction ID: {v.entry?.transactionNumber}</Typography>
                                <Typography>Card #: {v.entry?.cardNo}</Typography>
                                <Typography>Where: {v.paidExit?.property}</Typography>
                                <Typography>Entry Time: {v.entry?.transactionDate}</Typography>
                                <Typography>Exit Time: {v.paidExit?.exitDateTime}</Typography>
                                <Typography>Total Price: {v.paidExit?.totalPrice}</Typography>
                            </CardContent>
                        </Card>
                    </>
                } else {
                    const a = barcodeExit!!
                    const whenAdded = dayjs(a.whenReceived)
                    return <Card>
                        <CardContent>
                            <Typography variant="h6">Barcode Exit</Typography>
                            <Typography>Where: {a.use.property}</Typography>
                            <Typography>When Used: {a.use.timeOut}</Typography>
                            <Typography>When Received: {whenAdded.format("YYYY-MM-DD")} {whenAdded.format("h:mm a ZZ")}</Typography>
                            <Typography>Authorization ID: {a.use.id}</Typography>
                        </CardContent>
                    </Card>
                }

            })}
        </Stack>
    </>)
}


const purchaseProperties = (a: ActivityListItem): Record<string, any> | undefined => {
    if (a.details.sl_type === "sl_purchase") {
        return toDetails(a.details.sl_items ?? [])
    } else {
        return undefined
    }
}

const isPickyDetail = (a: ActivityListItem): boolean => {
    return (
        a.details.sl_status === "submitted"
        ||
        a.details.sl_status === "pending"
        ||
        a.type_name === "sl_member_attribute"
        ||
        a.type_name === "sl_login"
    )
}


const getBrandName = (a: ActivityListItem) => (purchaseProperties(a) ?? {})["BrandName"] ?? ""

const distinct = <T,>(x: T[]) => Array.from(new Set(x))

export const ActivityHistory = (props: { memberId: string, setFocus: (a: ActivityListItem) => void }) => {
    const { memberId, setFocus } = props
    const [error, setError] = useState<string>()
    const [searching, setSearching] = useState(false)
    const [activities, setActivities] = useState<ActivityListItem[]>()

    const [showAttributeChanges, setShowAttributeChanges] = useState(false)

    const [filterTime, setFilterTime] = useState(false)
    const [rangeStart, setRangeStart] = useState<Dayjs>()
    const [rangeEnd, setRangeEnd] = useState<Dayjs>()


    const [filterAmounts, setFilterAmounts] = useState(false)
    const [amountRangeLow, setAmountRangeLow] = useState<number>()
    const [amountRangeHigh, setAmountRangeHigh] = useState<number>()

    const [brandName, setBrandName] = useState<string>()
    const [filterBrands, setFilterBrands] = useState(false)

    const brandNames = distinct(activities?.map(getBrandName) ?? []).map(i => i.trim()).filter(i => i.length > 0)

    const matchesFilter = (a: ActivityListItem): boolean => {
        const passesPickyDetailsFilter = showAttributeChanges || !isPickyDetail(a)

        const when = dayjs(a.activity_ts)
        const passesDateStartFilter = (filterTime && rangeStart) ? !when.isBefore(rangeStart) : true
        const passesDateEndFilter = (filterTime && rangeEnd) ? !rangeEnd.isBefore(when) : true
        const passesDateFilter = passesDateStartFilter && passesDateEndFilter

        const amount = a.details.sl_total
        const passesAmountLowFilter = (filterAmounts && amountRangeLow) ? (amount === undefined ? false : amount >= amountRangeLow) : true
        const passesAmountHighFilter = (filterAmounts && amountRangeHigh) ? (amount === undefined ? false : amount <= amountRangeHigh) : true
        const passesAmountFilter = passesAmountLowFilter && passesAmountHighFilter

        const passesBrandFilter = (filterBrands && brandName && brandName.length > 0) ? getBrandName(a) == brandName : true

        return passesPickyDetailsFilter && passesDateFilter && passesAmountFilter && passesBrandFilter
    }


    useEffect(() => {
        if (memberId) {
            setSearching(true)
            let exclusions: string[]

            if (!showAttributeChanges) {
                exclusions = ["sl_member_attribute", "sl_login"]
            } else {
                exclusions = []
            }

            getActivitiesForMember(memberId, exclusions, (activities, error) => {
                setSearching(false)
                setActivities(activities)
                setError(error)
            })
        }
    }, [showAttributeChanges])

    return (<>



        <Stack>
            <Stack direction="row" spacing={3} >
                <FormControlLabel control={<Checkbox
                    checked={!showAttributeChanges}
                    onChange={e => {
                        setShowAttributeChanges(!e.target.checked)
                    }}
                />} label="Hide Picky Details" />
            </Stack>

            <Stack direction="row" spacing={1} >
                <FormControlLabel control={<Checkbox
                    checked={filterTime}
                    onChange={e => {
                        setFilterTime(e.target.checked)
                    }}
                />} label="Only In Time Range" />
                {filterTime && <Stack direction="row" spacing={3} style={{ marginTop: "20px" }}>
                    <DateTimePicker label="Earliest" value={rangeStart} onChange={v => setRangeStart(dayjs(v))} />
                    <DateTimePicker label="Latest" value={rangeEnd} onChange={v => setRangeEnd(dayjs(v))} />
                </Stack>}
            </Stack>

            <Stack direction="row" spacing={3} >
                <FormControlLabel control={<Checkbox
                    checked={filterAmounts}
                    onChange={e => {
                        setFilterAmounts(e.target.checked)
                    }}
                />} label="Only In Purchase Amount Range" />
                {filterAmounts && <Stack direction="row" spacing={3} style={{ marginTop: "20px" }}>
                    <TextField label="Lowest" type="number" value={amountRangeLow} onChange={v => setAmountRangeLow(parseFloat(v.target.value))} />
                    <TextField label="Highest" type="number" value={amountRangeHigh} onChange={v => setAmountRangeHigh(parseFloat(v.target.value))} />
                </Stack>}
            </Stack>

            <Stack direction="row" spacing={3} >
                <FormControlLabel control={<Checkbox
                    checked={filterBrands}
                    onChange={e => {
                        setFilterBrands(e.target.checked)
                    }}
                />} label="Only Purchases at Brand" />

                {filterBrands && <Stack direction="row" spacing={3} style={{ marginTop: "20px" }}>
                    <FormControl style={{ minWidth: "200px" }}>
                        <InputLabel id="demo-simple-select-label">Brand</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Brand" value={brandName} onChange={e => setBrandName(e.target.value)}>
                            {brandNames.map(brandName => {
                                return <MenuItem value={brandName}>{brandName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Stack>}
            </Stack>
        </Stack>

        {searching && <CircularProgress />}

        {error && <Alert color="error">{error}</Alert>}

        <ActivityList
            activitiesToShow={activities?.filter(matchesFilter) ?? []}
            setFocus={setFocus}
        />

    </>)

}


const formatPhoneNumber = (phoneNumber: PhoneNumber): string => {
    if (phoneNumber.countryCode == "1") {
        const areaCode = phoneNumber.number.substring(0, 3)
        const subscriber3 = phoneNumber.number.substring(3, 6)
        const subscriber4 = phoneNumber.number.substring(6)
        const formatted = `+1 (${areaCode}) ${subscriber3}-${subscriber4}`

        console.log("Formatted", phoneNumber, "as", formatted)

        return formatted
    } else {
        return `+${phoneNumber.countryCode} ${phoneNumber.number}`
    }
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ResultHolder<T> {
    value: T
}


const SmsLinkEntryPanel = (props:{memberId:string, onLink:(phoneNumber:string)=>void})=>{
    const {memberId, onLink} = props
    const [val, setVal] = useState("")
    const [doConfigure, setConfigure] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()

    const doPhoneNumberLink = () => {
        setError(undefined)
        setLoading(true)
        setSmsNumberForMember(memberId, { phoneNumber: val })
            .catch(setError)
            .then(()=>onLink(val))
            .finally(()=>setLoading(false))
    }

    const closeDialog = ()=> setConfigure(false)

    return <>
        <Alert color="warning" style={{maxWidth:"800px"}}>There is no SMS Login number assosciated with this account </Alert>
        {!doConfigure && <Button onClick={()=>setConfigure(true)}>Configure SMS Login</Button>}
        {doConfigure && (
            <Dialog open={true} onClose={closeDialog}>
                    <DialogTitle>Configure SMS Login Number</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will grant anyone with access to SMS messages sent to this number to login to this account.
                    </DialogContentText>
                    <Stack spacing={2} style={{marginTop:"20px"}}>
                        <MuiTelInput
                            label="Phone Number"
                            autoFocus
                            defaultCountry="US"
                            value={val}
                            onChange={setVal} />
                        {error && <Alert color="error">{error}</Alert>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={closeDialog}>Cancel</Button>
                    <Button disabled={loading} variant="contained" onClick={doPhoneNumberLink}>Set SMS Login</Button>
                </DialogActions>
            </Dialog>
)
        }

    </>
}


const LoginConfigurationPanel = (props: { memberId: string, onChange: () => void }) => {
    const { memberId, onChange } = props
    const [smsAccount, setSmsAccount] = React.useState<ResultHolder<SmsAccountDto | undefined>>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()

    const operationFinishedWithPossibleChange = () => {
        console.log("operationFinishedWithPossibleChange")
        setLoading(false)
        reload().then(onChange)
    }

    const reload = () => {
        return getSmsAccountForMember(memberId)
            .then(r => setSmsAccount({ value: r }))
            .catch(setError)
    }

    useEffect(() => {
        reload()
    }, [])



    const removePhoneNumberLink = () => {
        if (memberId && window.confirm(`This will break the ability for member ${memberId} to login using SMS to ${smsAccount?.value && formatPhoneNumber(smsAccount.value.phoneNumber)}.  Proceed?`)) {
            setError(undefined)
            setLoading(true)
            removeSmsNumberForMember(memberId)
                .catch(setError)
                .finally(operationFinishedWithPossibleChange)
        }
    }

    if (!smsAccount || loading) {
        return <CircularProgress />
    } else {
        return <Stack spacing={2}>
            <Stack style={{ marginTop: "10px" }} spacing={2}>
                {smsAccount.value && <Box>
                    <Card style={{maxWidth:"800px"}}>
                        <CardContent>

                        <Typography gutterBottom variant="h6" component="div">
                        SMS Login
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                         {formatPhoneNumber(smsAccount.value.phoneNumber)}
                        </Typography>
                        </CardContent>
                        <CardActionArea>
                        </CardActionArea>

                        <CardActions>
                        <   Button onClick={removePhoneNumberLink}>Remove SMS Association</Button>
                            </CardActions>
                    </Card>
                    </Box>}
                {!smsAccount.value && <>
                    <SmsLinkEntryPanel memberId={memberId} onLink={operationFinishedWithPossibleChange}/>
                    {/* <Box>SMS Login: none <Button onClick={doPhoneNumberLink}>Set SMS Login</Button></Box> */}
                </>}
                {error && <Alert color="error">{error}</Alert>}
            </Stack>
        </Stack>
    }
}

export default (props: {}) => {
    const { memberId } = useParams()
    const [member, setMember] = useState<Member>()
    const [memberCard, setMemberCard] = React.useState<MemberCardDto>()
    const [loading, setLoading] = useState(false)

    const [focus, setFocus] = useState<ActivityListItem>()
    const [error, setError] = useState<string>()

    const loadAll = () => {
        if (memberId) {
            setError(undefined)
            setLoading(true)
            Promise.all([
                getMemberCard(memberId).then(setMemberCard),
                getMemberPromise(memberId).then(setMember),
            ])
                .catch(setError)
                .finally(() => setLoading(false))

        }
    }

    useEffect(loadAll, [])

    const [tabIdx, setTabIdx] = useState(0)
    const tabs: Record<string, TabThing> = {
        "activities": {
            name: "Activities",
            content: () => <>{memberId && <ActivityHistory
                memberId={memberId}
                setFocus={setFocus} />}</>
        },
        "login": {
            name: "Login",
            content: () => <LoginConfigurationPanel memberId={memberId!!} onChange={loadAll} />
        },
        "coins": {
            name: "Coin Tranches",
            content: () => <>{memberId && <CoinTranchesHistory
                memberId={memberId}
                setFocus={setFocus} />}</>
        },
        "wallet": {
            name: "Wallet",
            content: () => <>{memberId && <WalletView
                memberId={memberId} />}</>
        },
        "fixes": {
            name: "Fixes",
            content: () => <>
                {memberId && <MemberFixesTab
                    memberId={memberId}
                    setFocus={setFocus}
                />}
            </>
        },
        "api": {
            name: "API",
            content: () => <>
                <Stack spacing={4}>
                    <TheEventsViewer
                        defaultLookbackMinutes={60 * 24 * 7}
                        memberIdFilter={memberId}
                        memberLinkMaker={memberId => memberId} />
                </Stack>
            </>
        },
        "plaid": {
            name: "Plaid",
            content: () => <>
                <Box style={{ textAlign: "center" }}>
                    <ExportLink name="Plaid Transactions" url={`/plaid/transactions/by-member/${memberId}`} />
                </Box>
            </>
        },

        "fidel": {
            name: "Fidel",
            content: () => <>
                {memberId && <CardsPanel memberId={memberId} />}
            </>
        },
        "parking-auth": {
            name: "Parking Auth",
            content: () => <>
                {memberId && <ParkingAuthorizationsPanel memberId={memberId} />}
            </>
        },
        "parking-exits": {
            name: "Parking Activity",
            content: () => <>
                {memberId && <ParkingExitsPanel memberId={memberId} />}
            </>
        }
    }


    console.log("Error is", error)

    return (<Stack style={{ margin: "20px" }} spacing={3}>

        <Typography variant="h2">Member</Typography>

        <Grid container spacing={3}>
            <Grid xs={12} sm={6}>
                {(member && memberCard) &&
                    <MembershipCard
                        member={member}
                        cardInfo={memberCard}
                    />
                }
            </Grid>
            <Grid xs={12} sm={6}>
                {(loading || !(member && memberCard)) && <CircularProgress />}
            </Grid>

        </Grid>

        {error && <Alert color="error">{error}</Alert>}
        <RoutedTabs propertyName="tabName" tabs={tabs} />

        {focus && (
            <Dialog
                fullScreen
                open={true}
                onClose={() => setFocus(undefined)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setFocus(undefined)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Activity Raw Data
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(focus.details, null, 4)}</Box>
                </DialogContent>
            </Dialog>)}
    </Stack>)
}
