import { get, postJson, sendJson } from "./http";
import { ActivityListItem, CarusoMap, DecoratedTagConfig, GoofyPayloadWrapper, MapWithContext, Member, MemberCardDto, MemberSearchResult, OfferOverview, OutsiderTagInfo, PhoneNumber, SmsAccountDto, SmsAccountLinkRequest, SMSTestAccountConfig, SMSTestConfig, StoredReferrerCode, TagConfig, TagConfigList, WalletPayload } from "./api"

export const getMemberPromise = (memberId: string):Promise<Member> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get(`/api/users/${memberId}`, (status, body) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(body))
            } else {
                resolveFailed(`Server said ${status}: ${body}`)
            }
        })
    })
}

export const searchMembers = (searchTerm: string, onResults: (results: MemberSearchResult[] | undefined) => void) => {
    get("/api/users?search=" + encodeURIComponent(searchTerm), (status, body) => {
        if (status === 200) {
            onResults(JSON.parse(body))
        } else {
            onResults(undefined)
        }
    })
}

export const getReferralCodes = ():Promise<StoredReferrerCode[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get("/api/referrer-codes", (status, text) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(text)
            }
        })
    })
}
export type TagStatusFilter = "active"|"expired"
export const getTags = (filter:TagStatusFilter, onResults: (c: TagConfigList | undefined) => void) => {
    get(`/api/tags?status=${filter}`, (status, text) => {
        if (status === 200) {
            onResults(JSON.parse(text))
        } else {
            onResults(undefined)
        }
    })
}
export const getTagsPromise = ():Promise<TagConfigList> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get("/api/tags", (status, text) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(undefined)
            }
        })
    })
}

export const createTag = (tag:TagConfig):Promise<DecoratedTagConfig> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        postJson("/api/tags", JSON.stringify(tag),(status, text, xhr) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(`server said ${status}: ${text}`)
            }
        })
    })
}
export const updateTag = (tag:TagConfig):Promise<OutsiderTagInfo> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        sendJson("PUT", `/api/tags/${tag.tagId}`, JSON.stringify(tag),(status, text, xhr) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(`server said ${status}: ${text}`)
            }
        })
    })
}

export const getMap = (onResults: (c: CarusoMap | undefined) => void) => {

    get("/api/map", (status, text) => {
        if (status === 200) {
            onResults(JSON.parse(text))
        } else {
            onResults(undefined)
        }
    })
}
export const getMapPromise = ():Promise<CarusoMap> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get("/api/map", (status, text) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(undefined)
            }
        })
    })

}

export const listOffers = ():Promise<OfferOverview[]> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get("/api/offers", (status, text, ) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(`server said ${status}: $text`)
            }
        })
    })
}

export const getContextualizedMapPromise = ():Promise<MapWithContext> => {
    return new Promise((resolveSuccess, resolveFailed)=>{

        get("/api/map-with-context", (status, text) => {
            if (status === 200) {
                const withContext: MapWithContext = JSON.parse(text)
                resolveSuccess(withContext)
            } else {
                resolveFailed(undefined)
            }
        })
    })
}

export const getContextualizedMap = (onResults: (c: MapWithContext | undefined) => void) => {
    getContextualizedMapPromise()
        .then(onResults)
        .catch(()=>onResults(undefined))
}




export const getSmsTestingConfig = ():Promise<SMSTestConfig> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get(`/api/sms-testing-config`, (status, text) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(text))
            } else {
                resolveFailed(text)
            }
        })
    })
}

export const saveSmsTestingConfig = (newConfig:SMSTestConfig):Promise<boolean> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        sendJson("PUT", `/api/sms-testing-config`, JSON.stringify(newConfig), (status, text) => {
            if (status === 200) {
                resolveSuccess(true)
            } else {
                resolveFailed(text)
            }
        })
    })
}


export const getActivitiesForMember = (memberId: string, excludedTypes:string[], onResult: (results: ActivityListItem[] | undefined, error: string | undefined) => void) => {
    get(`/api/users/${memberId}/activities?excluded-types=${excludedTypes.join(",")}`, (status, body) => {
        if (status === 200) {
            onResult(JSON.parse(body), undefined)
        } else {
            onResult(undefined, `Status ${status}: ${body}`)
        }
    })
}

export const getActivitiesForMemberOfType = (memberId: string, includedTypes:string[], onResult: (results: ActivityListItem[] | undefined, error: string | undefined) => void) => {
    get(`/api/users/${memberId}/activities?included-types=${includedTypes.join(",")}`, (status, body) => {
        if (status === 200) {
            onResult(JSON.parse(body), undefined)
        } else {
            onResult(undefined, `Status ${status}: ${body}`)
        }
    })
}


export const getWallet = (memberId:string):Promise<GoofyPayloadWrapper<WalletPayload>> => {
    return new Promise((resolveSuccess, resolveFailed)=>{
        get(`/api/users/${memberId}/wallet`, (status, body) => {
            if (status === 200) {
                resolveSuccess(JSON.parse(body))
            } else {
                resolveFailed(body)
            }
        })
    })
}


export const getMemberCard = (memberId:string):Promise<MemberCardDto> => {
    return fetch(`/api/members/${memberId}/membership-card`)
        .then(r=>{
            if(r.status == 200){
                return r.text()
            }else{
                throw `server said ${r.status}: ${r.statusText}`
            }
        })
        .then(text=>JSON.parse(text) as MemberCardDto)
}

const defaultPromiseErrorWithStatusAndBody = <T,>(r:Response):Promise<T>=>{
    return r.text().then((body)=> {
        throw `The server responded with "${r.status}/${r.statusText}": ${body}`
    })
}

export const getSmsAccountForMember = (memberId:string):Promise<SmsAccountDto|undefined> => {
    return fetch(`/api/members/${memberId}/sms-link`)
        .then(r=>{
            if(r.status == 200){
                return r.text().then(text=>JSON.parse(text) as SmsAccountDto)
            }else if(r.status == 404){
                return undefined
            }{
                return defaultPromiseErrorWithStatusAndBody(r)
            }
        })
        
}

export const setSmsNumberForMember = (memberId:string, req:SmsAccountLinkRequest):Promise<SmsAccountDto|undefined> => {
    return fetch(`/api/members/${memberId}/sms-link`, {method:"PATCH", body:JSON.stringify(req)})
        .then(r=>{
            if(r.status == 200){
                return r.text().then(text=>JSON.parse(text) as SmsAccountDto)
            }else if(r.status == 404){
                return undefined
            }{
                return defaultPromiseErrorWithStatusAndBody(r)
            }
        })
}

export const removeSmsNumberForMember = (memberId:string):Promise<any> => {
    return fetch(`/api/members/${memberId}/sms-link`, {method:"DELETE"})
        .then(r=>{
            if(r.status == 200){
                return "Did it"
            }else if(r.status == 404){
                return "Wasn't anything to do?"
            }{
                return defaultPromiseErrorWithStatusAndBody(r)
            }
        })
}