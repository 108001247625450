import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import SingleUseCodeExperience from './SingleUseCodeExperience';
import AdminApp from './AdminApp';
import ValetApp from './ValetApp';
import DeepLinks from './DeepLinks';
import ScanTest from './ScanTest';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime";
import { MasterCardLinkTest } from './MasterCardLinkTest';
import PlaidLinkTest from './PlaidLinkTest';
import EventsViewer from './EventsViewer';
import JoinExperience from './JoinExperience';

dayjs.extend(relativeTime)

const router = createBrowserRouter([
  { path: "/",  element: <div>Hello world!</div>},
  { path: "/j/:referrerId",  element: <JoinExperience/>},
  { path: "/codes/*",  element: <SingleUseCodeExperience/>},
  { path: "/admin/*",  element: <AdminApp/>},
  { path: "/events/*",  element: <EventsViewer style={{margin:"20px"}}/>},
  { path: "/show/*",  element: <ValetApp/>},
  { path: "/deeplinks", element: <DeepLinks/>},
  { path: "/scan", element: <ScanTest/>},
  { path: "/mastercard", element: <MasterCardLinkTest/>},
  { path: "/plaid", element: <PlaidLinkTest/>},
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  // </React.StrictMode>
);

